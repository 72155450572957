<template>
    <b-row>
        <b-col align-self="center">
            <div>
                <h1 class="heading">Protect your jewelry purchase</h1>
                <div class="sub-heading">
                    <h3>Enter your appraisal information to get your free insurance quote</h3>
                </div>
                <b-row>
                    <b-col cols="6" class="left-content">
                        <div class="document-number">
                            <p>
                                Enter your document number
                                <b-link @click="showDocNumberModal">
                                    (Where do I find this?)
                                </b-link>
                            </p>
                            <TextInput
                                name="appraisalId"
                                :model="appraisalId"
                                :validator="appraisalFieldValidator"
                                :show-error="!valid.appraisalId"
                                :error-label="appraisalErrorLabel"
                                @inputChanged="val => (appraisalId = val)"
                                @validate="val => (valid.appraisalId = val)"
                            />
                        </div>
                        <div class="zip-code">
                            <p>Enter your zip/postal code as shown on your appraisal</p>
                            <TextInput
                                name="zipcode"
                                :model="zipCode"
                                :validator="zipFieldValidator"
                                :show-error="!valid.zipCode"
                                :error-label="zipErrorLabel"
                                @inputChanged="val => (zipCode = val)"
                                @validate="val => (valid.zipCode = val)"
                            />
                        </div>
                        <div class="acknowledgement">
                            By clicking the "Get My Quote" button you authorize Jewelers Mutual Group to use a secure
                            system to retrieve and save details of this purchase for the purpose of calculating an
                            insurance quote.
                            <br />
                            Retrieved information may include your name and address.
                        </div>
                        <div class="submit-button">
                            <Button text="Get my quote" type="submit" @clickEvent="getQuote" />
                        </div>
                        <div class="no-appraisal">
                            <span>
                                Didn’t get an appraisal recently?
                                <b-link
                                    href="https://my.jewelersmutual.com/jewelry-insurance-quote-apply/t/QFI47MeHQkqdFZqiq1ECAQ#quote"
                                >
                                    Get a quote here
                                </b-link>
                            </span>
                        </div>
                    </b-col>
                    <b-col cols="6" class="right-content">
                        <img class="document-id-image" :src="require(`@/assets/customers-shopping.jpg`)" />
                    </b-col>
                </b-row>
            </div>
        </b-col>
        <div>
            <b-modal ref="docNumberModal" title="Where to find the document number">
                <template #modal-footer="{ ok, cancel }">
                    <div class="footer-buttons">
                        <Button type="button" text="Ok" cancel @clickEvent="hideDocNumberModal()" />
                    </div>
                </template>
                <b-row>
                    <b-col>
                        <img class="document-id-image" :src="require(`@/assets/find-document-id.png`)" />
                    </b-col>
                </b-row>
            </b-modal>
        </div>
        <div>
            <b-modal ref="notFoundModal" title="Appraisal not found">
                <template #modal-footer="{ ok, cancel }">
                    <div class="footer-buttons">
                        <Button type="button" text="Ok" cancel @clickEvent="hideNotFoundModal()" />
                    </div>
                </template>
                <b-row>
                    <b-col>
                        We could not find your appraisal, please verify your information is correct, or reach out to us
                        by calling
                        <b-link href="tel:888-346-9464">888-346-9464</b-link>
                        .
                    </b-col>
                </b-row>
            </b-modal>
        </div>
    </b-row>
</template>

<script>
import axios from 'axios';
import TextInput from '@/components/FormGroups/TextInput';
import Button from '@/components/FormGroups/Button';
import { isValidCaZipCode, isValidUsZipCode } from '@/validationRules';

export default {
    name: 'AppraisalLookup',
    components: {
        TextInput,
        Button
    },
    data() {
        return {
            appraisalId: '',
            zipCode: '',
            valid: {
                appraisalId: true,
                zipCode: true
            }
        };
    },
    computed: {
        zipErrorLabel() {
            if (this.zipCode !== '') {
                return 'Please enter a valid zip/postal code.';
            }
            return 'Zip/Postal Code is required.';
        },
        appraisalErrorLabel() {
            return 'Document number is required.';
        }
    },
    methods: {
        isValidZipCode(v) {
            return v && (isValidUsZipCode(v) || isValidCaZipCode(v));
        },
        zipFieldValidator(v) {
            return this.isValidZipCode(v);
        },
        appraisalFieldValidator() {
            return this.appraisalId !== '';
        },
        async getQuote() {
            if (!this.appraisalId || !this.zipCode) {
                if (!this.appraisalId) {
                    this.valid.appraisalId = false;
                }

                if (!this.zipCode || this.isValidZipCode(this.zipCode)) {
                    this.valid.zipCode = false;
                }

                return;
            }

            try {
                const { data } = await axios.get(`/api/appraisal/${this.appraisalId.toUpperCase()}`);
                if (
                    !data ||
                    !data.customer ||
                    data.customer.postalCode.replace(/\s/g, '') !== this.zipCode.replace(/\s/g, '')
                ) {
                    this.$refs.notFoundModal.show();
                } else if (data.items) {
                    let sumValue = 0;
                    let hasOther = false;
                    data.items.forEach(item => {
                        sumValue += item.totalValue;

                        if (item.jewelryType === 'other') {
                            hasOther = true;
                        }
                    });

                    if (
                        (data.customer.countryCode.includes('CA') && sumValue > 5000) ||
                        (data.customer.countryCode.includes('US') && sumValue > 100000) ||
                        hasOther
                    ) {
                        window.location.href = 'https://my.jewelersmutual.com/jewelry-insurance-quote-apply/';
                    } else {
                        this.$router.push(`/in/${this.appraisalId.toUpperCase()}`);
                    }
                }
            } catch (ex) {
                this.$refs.notFoundModal.show();
            }
        },
        hideNotFoundModal() {
            this.$refs.notFoundModal.hide();
        },
        showDocNumberModal() {
            this.$refs.docNumberModal.show();
        },
        hideDocNumberModal() {
            this.$refs.docNumberModal.hide();
        }
    }
};
</script>

<style lang="scss" scoped>
.sub-heading {
    margin: 20px 0 10px 0;
}

.document-id-image {
    width: 100%;
}

.left-content {
    margin: 40px 0;

    p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
    }

    .zip-code {
        margin-top: 25px;
    }

    .acknowledgement {
        font-size: 12px;
        font-style: italic;
    }

    .submit-button {
        margin-top: 20px;
    }

    .no-appraisal {
        margin-top: 20px;
    }
}

.right-content {
    margin: 40px 0;
    padding: 0 40px;
}
</style>
